import { Form } from "../formsTypes";

const resForms: Form[] = [
  {
    name: "Système de variation de puissance en éclairage extérieur",
    id: "RES-EC-103",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/RES-EC-103.pdf",
    type: "Éclairage",
    fields: [
      {
        id: "amount",
        title: "Montant en kWh cumac par W installé",
        type: "value",
        value: "8",
      },
      {
        id: "power",
        title: "Puissance installée de l'éclairage régulé en W",
        type: "number",
      },
    ],
    attachments: [],
    endDate: "2025-01-01T02:00:00.000Z",
  },
  {
    name: "Rénovation d'éclairage extérieur",
    id: "RES-EC-104",
    formUrl: "https://gdceeforms.blob.core.windows.net/v62/RES-EC-104.pdf",
    type: "Éclairage",
    fields: [
      {
        id: "case",
        title: "Type de fonctionnement des luminaires neufs",
        type: "choice",
        choices: [
          {
            name: "Cas n°1 : fonctionnement avec gradation seule",
            value: "4000",
          },
          {
            name: "Cas n°2 : fonctionnement avec gradation et détection de présence",
            value: "5600",
          },
        ],
      },
      {
        id: "lights",
        title: "Nombre de luminaires remplacés ou rééquipés",
        type: "number",
      },
    ],
    attachments: [
      "Document daté et signé par le bénéficiaire indiquant la localisation et le nombre des luminaires installés",
      "Preuves de recyclage des luminaires ou des sources lumineuses déposés dans le cadre de l'opération",
    ],
    startDate: "2025-01-01T02:00:00.000Z",
    history: [
      {
        name: "Rénovation d'éclairage extérieur",
        id: "RES-EC-104",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/RES-EC-104.pdf",
        type: "Éclairage",
        fields: [
          {
            id: "case",
            title: "Type de luminaire neuf",
            type: "choice",
            choices: [
              {
                name: "Cas n°1 : fonctionnement avec gradation seule",
                value: "9300",
              },
              {
                name: "Cas n°2 : fonctionnement avec gradation et détection de présence",
                value: "7200",
              },
            ],
          },
          {
            id: "lights",
            title: "Nombre de luminaires installés",
            type: "number",
          },
        ],
        attachments: [],
      },
    ],
  },
];

export default resForms;

export const activeForms: string[] = resForms.filter((f) => f.fields.length > 0).map((f) => f.id);
