import { Form } from "../formsTypes";

const agriForms: Form[] = [
  {
    name: "Module d'intégration de température installé sur un ordinateur climatique",
    id: "AGRI-EQ-101",
    type: "Équipement",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-EQ-101.pdf",
    fields: [
      {
        id: "type",
        title: "Type de serres",
        type: "choice",
        choices: [
          {
            name: "Serres maraîchères",
            value: "83",
          },
          {
            name: "Serres horticoles",
            value: "70",
          },
        ],
      },
      {
        id: "surface",
        title: "Surface de serre chauffée (m²)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Double écran thermique",
    id: "AGRI-EQ-102",
    type: "Équipement",
    formUrl: "https://gdceeforms.blob.core.windows.net/v65/AGRI-EQ-102.pdf",
    fields: [
      {
        id: "type",
        title: "Type de serres",
        type: "choice",
        choices: [
          {
            name: "Serres maraîchères",
            value: "290",
          },
          {
            name: "Serres horticoles",
            value: "240",
          },
        ],
      },
      {
        id: "surface",
        title: "Surface de la serre équipée (m²)",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2025-04-01T01:00:00.000Z",
    endDate: "2030-04-01T01:00:00.000Z",
    history: [
      {
        name: "Double écran thermique",
        id: "AGRI-EQ-102",
        type: "Équipement",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-EQ-102.pdf",
        fields: [
          {
            id: "type",
            title: "Type de serres",
            type: "choice",
            choices: [
              {
                name: "Serres maraîchères",
                value: "410",
              },
              {
                name: "Serres horticoles",
                value: "280",
              },
            ],
          },
          {
            id: "surface",
            title: "Surface de la serre équipée (m²)",
            type: "number",
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Écrans thermiques latéraux",
    id: "AGRI-EQ-104",
    type: "Équipement",
    formUrl: "https://gdceeforms.blob.core.windows.net/v62/AGRI-EQ-104.pdf",
    fields: [
      {
        id: "type",
        title: "Type de serres",
        type: "choice",
        choices: [
          {
            name: "Serres maraîchères",
            value: "48",
          },
          {
            name: "Serres horticoles",
            value: "22",
          },
        ],
      },
      {
        id: "surface",
        title: "Surface au sol de la serre équipée (m²)",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2025-01-01T02:00:00.000Z",
    history: [
      {
        name: "Écrans thermiques latéraux",
        id: "AGRI-EQ-104",
        type: "Équipement",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-EQ-104.pdf",
        fields: [
          {
            id: "type",
            title: "Type de serres",
            type: "choice",
            choices: [
              {
                name: "Serres maraîchères",
                value: "42",
              },
              {
                name: "Serres horticoles",
                value: "22",
              },
            ],
          },
          {
            id: "surface",
            title: "Surface au sol de la serre équipée (m²)",
            type: "number",
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Stop & Start pour véhicules agricoles à moteur",
    id: "AGRI-EQ-105",
    type: "Équipement",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-EQ-105.pdf",
    fields: [
      {
        id: "ratio",
        title: "Montant en kWh cumac par véhicule",
        type: "value",
        value: "18400",
      },
      {
        id: "quantity",
        title: "Nombre de véhicules concernés par l'opération",
        type: "number",
      },
    ],
    attachments: ["Copie du certificat d'immatriculation, + pv de réception en cas d'adjonction du dispositif"],
  },
  {
    name: "Régulation de la ventilation des silos et des installations de stockage en vrac de céréales",
    id: "AGRI-EQ-106",
    type: "Équipement",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-EQ-106.pdf",
    fields: [
      {
        id: "type",
        title: "Type de régulation",
        type: "choice",
        choices: [
          {
            name: "Par capteurs thermiques seulement",
            value: "10",
          },
          {
            name: "Par capteurs thermiques couplés à une régulation de vitesse",
            value: "12",
          },
        ],
      },
      {
        id: "volume",
        title: "Volume de céréales (m³)",
        type: "number",
      },
    ],
    attachments: ["Relevé attestant du volume de céréales", "Étude préalable à la mise en place du système"],
  },
  {
    name: "Isolation des parois de serre",
    id: "AGRI-EQ-107",
    type: "Équipement",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-EQ-107.pdf",
    fields: [
      {
        id: "type",
        title: "Type de culture",
        type: "choice",
        choices: [
          {
            name: "Serres maraîchères",
            value: "170",
          },
          {
            name: "Serres horticoles",
            value: "92",
          },
        ],
      },
      {
        id: "surface",
        title: "Surface de la serre équipée (m²)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Stockage d'eau pour une serre bioclimatique",
    id: "AGRI-EQ-108",
    type: "Équipement",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-EQ-108.pdf",
    fields: [
      {
        id: "ratio",
        title: "Montant de kWh cumac par m² de serre équipée",
        type: "value",
        value: "390",
      },
      {
        id: "surface",
        title: "Surface de la serre équipée (m²)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Couverture performante de serre",
    id: "AGRI-EQ-109",
    type: "Équipement",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-EQ-109.pdf",
    fields: [
      {
        id: "type",
        title: "Type de serre",
        type: "choice",
        choices: [
          {
            name: "Serre maraîchère",
            value: "1",
          },
          {
            name: "Serre horticole",
            value: "2",
          },
        ],
      },
      {
        id: "insulation",
        title: "Type de paroi",
        type: "choice",
        choices: [
          {
            name: "Double paroi ETFE, double paroi verre - ETFE ou simple paroi verre",
            value: "1",
          },
          {
            name: "Double paroi en verre",
            value: "2",
          },
        ],
      },
      {
        id: "type|insulation",
        title: "Montant de kWh cumac par m² de serre équipée",
        type: "multichoice",
        choices: [
          {
            name: "1|1",
            value: "1060",
          },
          {
            name: "1|2",
            value: "1930",
          },
          {
            name: "2|1",
            value: "490",
          },
          {
            name: "2|2",
            value: "900",
          },
        ],
      },
      {
        id: "surface",
        title: "Surface de la serre équipée (m²)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Séchage solaire par insufflation des produits et co-produits agricoles et forestiers utilisant des panneaux solaires hybrides",
    id: "AGRI-EQ-110",
    type: "Équipement",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-EQ-110.pdf",
    fields: [
      {
        id: "system",
        title: "Type de système",
        type: "choice",
        choices: [
          {
            name: "Système complet neuf de séchage",
            value: "1",
          },
          {
            name: "Toiture solaire couplée au système d'insufflation d'air existant",
            value: "2",
          },
        ],
      },
      {
        id: "products",
        title: "Produits et co-produits",
        type: "choice",
        choices: [
          {
            name: "Agricoles",
            value: "1",
          },
          {
            name: "Forestiers",
            value: "2",
          },
        ],
      },
      {
        id: "system|products",
        title: "Montant en kWh cumac par kW thermique installé",
        type: "multichoice",
        choices: [
          {
            name: "1|1",
            value: ":H:42700:H:48500:H:55700",
          },
          {
            name: "1|2",
            value: ":H:102600:H:116600:H:134100",
          },
          {
            name: "2|1",
            value: ":H:12200:H:13900:H:17400",
          },
          {
            name: "2|2",
            value: ":H:16900:H:19300:H:24100",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance thermique totale installée (kW)",
        type: "number",
      },
    ],
    attachments: ["Certification des panneaux solaires hybrides"],
  },
  {
    name: "Simple écran thermique",
    id: "AGRI-EQ-111",
    type: "Équipement",
    formUrl: "https://gdceeforms.blob.core.windows.net/v62/AGRI-EQ-111.pdf",
    fields: [
      {
        id: "type",
        title: "Type de serres",
        type: "choice",
        choices: [
          {
            name: "Serre maraîchère",
            value: "360",
          },
          {
            name: "Serre horticole",
            value: "240",
          },
        ],
      },
      {
        id: "surface",
        title: "Surface de serre équipée (m²)",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2024-08-31T02:00:00.000Z",
  },
  {
    name: "Double paroi gonflable",
    id: "AGRI-EQ-112",
    type: "Équipement",
    formUrl: "https://gdceeforms.blob.core.windows.net/v65/AGRI-EQ-112.pdf",
    fields: [
      {
        id: "type",
        title: "Type de serres",
        type: "choice",
        choices: [
          {
            name: "Serre maraîchère",
            value: "410",
          },
          {
            name: "Serre horticole",
            value: "290",
          },
        ],
      },
      {
        id: "surface",
        title: "Surface de serre équipée (m²)",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2025-01-01T02:00:00.000Z",
  },
  {
    name: "Contrôle et préconisations de réglage du moteur d'un tracteur",
    id: "AGRI-SE-101",
    type: "Service",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-SE-101.pdf",
    fields: [
      {
        id: "power",
        title: "Puissance du moteur du tracteur en chevaux",
        type: "choice",
        choices: [
          { name: "de 0 à 78", value: "8000" },
          { name: "de 79 à 133", value: "16800" },
          { name: "à compter de 134", value: "22400" },
        ],
      },
    ],
    attachments: ["Rapport de contrôle du moteur du tracteur"],
  },
  {
    name: "Dispositif de stockage d'eau chaude de type « Open Buffer »",
    id: "AGRI-TH-101",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v62/AGRI-TH-101.pdf",
    fields: [
      {
        id: "ratio",
        title: "Montant unitaire en kWh cumac par m²",
        type: "value",
        value: "96",
      },
      {
        id: "surface",
        title: "Surface de serres chauffée par le dispositif, en m²",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2025-01-01T02:00:00.000Z",
    history: [
      {
        name: "Dispositif de stockage d'eau chaude de type « Open Buffer »",
        id: "AGRI-TH-101",
        type: "Thermique",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-TH-101.pdf",
        fields: [
          {
            id: "ratio",
            title: "Montant unitaire en kWh cumac par m²",
            type: "value",
            value: "140",
          },
          {
            id: "surface",
            title: "Surface de serres chauffée par le dispositif, en m²",
            type: "number",
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Dispositif de stockage d'eau chaude",
    id: "AGRI-TH-102",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-TH-102.pdf",
    fields: [
      {
        id: "ratio",
        title: "Montant unitaire en kWh cumac par m²",
        type: "value",
        value: "130",
      },
      {
        id: "surface",
        title: "Surface de serres chauffée par le dispositif, en m²",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Pré-refroidisseur de lait",
    id: "AGRI-TH-103",
    type: "Froid",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-TH-103.pdf",
    fields: [
      {
        id: "ratio",
        title: "Montant en kWh cumac par litre de lait",
        type: "value",
        value: "0.113",
      },
      {
        id: "volume",
        title: "Production laitière annuelle de l'exploitation (litres/an)",
        type: "number",
      },
    ],
    attachments: ["Attestation de la performance énergétique du prérefroidisseur"],
  },
  {
    name: "Système de récupération de chaleur sur groupe de production de froid hors tanks à lait",
    id: "AGRI-TH-104",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-TH-104.pdf",
    fields: [
      {
        id: "useDuration",
        title: "Durée annuelle d'utilisation de la chaleur récupérée (D), en heures",
        type: "number",
      },
      {
        id: "factor",
        title: "Facteur multiplicatif",
        type: "value",
        value: "9.9",
      },
      {
        id: "power",
        title: "Puissance thermique récupérée en kW",
        type: "number",
      },
    ],
    attachments: ["Étude de dimensionnement préalable"],
  },
  {
    name: "Récupérateur de chaleur sur tank à lait",
    id: "AGRI-TH-105",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-TH-105.pdf",
    fields: [
      {
        id: "ratio",
        title: "Montant en kWh cumac par litre de lait",
        type: "value",
        value: "0.138",
      },
      {
        id: "volume",
        title: "Production laitière annuelle de l'exploitation (litres/an)",
        type: "number",
      },
    ],
    attachments: ["Validation du récupérateur de chaleur sur le tank à lait"],
  },
  {
    name: "Pompe à chaleur de type air/eau ou eau/eau",
    id: "AGRI-TH-108",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-TH-108.pdf",
    fields: [
      {
        id: "type",
        type: "choice",
        title: "Type de serre",
        choices: [
          {
            name: "Maraîchère",
            value: "1",
          },
          {
            name: "Horticole",
            value: "2",
          },
        ],
      },
      {
        id: "power",
        type: "choice",
        title: "Puissance thermique nominale de la PAC",
        skipValue: true,
        choices: [
          {
            name: "Inférieure ou égale à 400 kW",
            value: "1",
          },
          {
            name: "Supérieure à 400 kW",
            value: "2",
          },
        ],
      },
      {
        id: "efficacity",
        type: "choice",
        title: "Efficacité énergétique saisonnière (ηs)",
        showIf: {
          fieldId: "power",
          fieldValues: ["1"],
        },
        choices: [
          {
            name: "111% ≤ ηs < 126%",
            value: "1",
          },
          {
            name: "126% ≤ ηs",
            value: "2",
          },
        ],
      },
      {
        id: "type|efficacity",
        title: "Montant en kWh cumac par m² de serre chauffée",
        type: "multichoice",
        showIf: {
          fieldId: "power",
          fieldValues: ["1"],
        },
        choices: [
          {
            name: "1|1",
            value: "800",
          },
          {
            name: "1|2",
            value: "970",
          },
          {
            name: "2|1",
            value: "380",
          },
          {
            name: "2|2",
            value: "460",
          },
        ],
      },
      {
        id: "cop",
        type: "choice",
        title: "COP",
        showIf: {
          fieldId: "power",
          fieldValues: ["2"],
        },
        choices: [
          {
            name: "3.4 ≤ COP < 4",
            value: "1",
          },
          {
            name: "4 ≤ COP",
            value: "2",
          },
        ],
      },
      {
        id: "type|cop",
        title: "Montant en kWh cumac par m² de serre chauffée",
        type: "multichoice",
        showIf: {
          fieldId: "power",
          fieldValues: ["2"],
        },
        choices: [
          {
            name: "1|1",
            value: "780",
          },
          {
            name: "1|2",
            value: "1040",
          },
          {
            name: "2|1",
            value: "370",
          },
          {
            name: "2|2",
            value: "490",
          },
        ],
      },
      {
        id: "surface",
        title: "Surface de serre chauffée (m²)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Récupérateur de chaleur à condensation pour serres",
    id: "AGRI-TH-109",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-TH-109.pdf",
    fields: [
      {
        id: "type",
        title: "Type de serres",
        type: "choice",
        choices: [
          {
            name: "Serre horticole chauffée",
            value: "86",
          },
          {
            name: "Serre maraîchère chauffée",
            value: "9",
          },
        ],
      },
      {
        id: "surface",
        title: "Surface de serre chauffée (m²)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Chaudière à haute performance énergétique pour serres",
    id: "AGRI-TH-110",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-TH-110.pdf",
    fields: [
      {
        id: "type",
        title: "Type de serres",
        type: "choice",
        choices: [
          {
            name: "Serre horticole chauffée",
            value: "150",
          },
          {
            name: "Serre maraîchère chauffée",
            value: "19",
          },
        ],
      },
      {
        id: "surface",
        title: "Surface de serre chauffée (m²)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Échangeur-récupérateur de chaleur air/air dans un bâtiment d'élevage de volailles",
    id: "AGRI-TH-113",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-TH-113.pdf",
    fields: [
      {
        id: "ratio",
        title: "Montant en kWh cumac par m² de bâtiment équipé",
        type: "value",
        value: "250",
      },
      {
        id: "surface",
        title: "Surface de bâtiment équipé (m²)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Déshumidificateur thermodynamique pour serres",
    id: "AGRI-TH-117",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v65/AGRI-TH-117.pdf",
    fields: [
      {
        id: "ratio",
        title: "Montant du gain en kWh cumac par m²",
        type: "value",
        value: "710",
      },
      {
        id: "surface",
        title: "Surface de serre équipée (m²)",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2025-04-01T01:00:00.000Z",
    history: [
      {
        name: "Déshumidificateur thermodynamique pour serres",
        id: "AGRI-TH-117",
        type: "Thermique",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-TH-117.pdf",
        fields: [
          {
            id: "ratio",
            title: "Montant du gain en kWh cumac par m²",
            type: "value",
            value: "430",
          },
          {
            id: "surface",
            title: "Surface de serre équipée (m²)",
            type: "number",
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Double tube de chauffage pour serres",
    id: "AGRI-TH-118",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-TH-118.pdf",
    fields: [
      {
        id: "ratio",
        title: "Montant en kWh cumac par m² de serre équipée",
        type: "value",
        value: "370",
      },
      {
        id: "surface",
        title: "Surface de serre chauffée (m²)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Système de déshumidification avec air extérieur",
    id: "AGRI-TH-119",
    type: "Thermique",
    formUrl: "https://gdceeforms.blob.core.windows.net/v65/AGRI-TH-119.pdf",
    fields: [
      {
        id: "ratio",
        title: "Montant en kWh cumac par m²",
        type: "choice",
        choices: [
          { name: "Simple flux", value: "440" },
          { name: "Double flux", value: "760" },
        ],
      },
      {
        id: "surface",
        title: "Surface de serres équipées du dispositif, en m²",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2025-04-01T01:00:00.000Z",
    history: [
      {
        name: "Système de déshumidification avec air extérieur",
        id: "AGRI-TH-119",
        type: "Thermique",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-TH-119.pdf",
        fields: [
          {
            id: "ratio",
            title: "Montant en kWh cumac par m²",
            type: "value",
            value: "410",
          },
          {
            id: "surface",
            title: "Surface de serres équipées du dispositif, en m²",
            type: "number",
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Moto-variateur synchrone à aimants permanents ou à réluctance",
    id: "AGRI-UT-101",
    type: "Moteur",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-UT-101.pdf",
    fields: [
      {
        id: "application",
        title: "Application",
        type: "choice",
        choices: [
          {
            name: "Pompage d'irrigation",
            value: "1",
          },
          {
            name: "Ventilation de bâtiments d'élevage",
            value: "2",
          },
          {
            name: "Ventilation en serre",
            value: "3",
          },
          {
            name: "Pompe à vide d'une salle de traite",
            value: "4",
          },
          {
            name: "Chaufferie d'une serre (pompe, ventilateur brûleur)",
            value: "5",
          },
          {
            name: "Autres applications",
            value: "6",
          },
        ],
      },
      {
        id: "application",
        title: "Application",
        type: "multichoice",
        choices: [
          {
            name: "1",
            value: "2100",
          },
          {
            name: "2",
            value: "18300",
          },
          {
            name: "3",
            value: "14900",
          },
          {
            name: "4",
            value: "2100",
          },
          {
            name: "5",
            value: "6400",
          },
          {
            name: "6",
            value: "4500",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance nominale du moto-variateur en kW",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Système de variation électronique de vitesse sur un moteur asynchrone",
    id: "AGRI-UT-102",
    type: "Moteur",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-UT-102.pdf",
    fields: [
      {
        id: "application",
        title: "Application",
        type: "choice",
        choices: [
          {
            name: "Pompe d'irrigation",
            value: "1600",
          },
          {
            name: "Ventilateur de bâtiments d'élevage",
            value: "19600",
          },
          {
            name: "Ventilation d'une serre",
            value: "11500",
          },
          {
            name: "Pompe à vide d'une salle de traite",
            value: "2800",
          },
          {
            name: "Chauffage d'une serre (pompe, ventilateur d'un brûleur)",
            value: "7700",
          },
          {
            name: "Autres applications",
            value: "2300",
          },
        ],
      },
      {
        id: "power",
        title: "Puissance nominale du moteur en kW",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Système de régulation sur un groupe de production de froid permettant d'avoir une basse pression flottante",
    id: "AGRI-UT-103",
    type: "Froid",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-UT-103.pdf",
    fields: [
      {
        id: "ratio",
        title: "Montant en kWh cumac par kW",
        type: "value",
        value: "830",
      },
      {
        id: "power2",
        title: "Puissance électrique nominale du groupe de production de froid en kW",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2024-08-22T01:00:00.000Z",
    history: [
      {
        name: "Système de régulation sur un groupe de production de froid permettant d'avoir une basse pression flottante",
        id: "AGRI-UT-103",
        type: "Froid",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-UT-103.pdf",
        fields: [
          {
            id: "ratio",
            title: "Montant en kWh cumac par kW",
            type: "value",
            value: "830",
          },
          {
            id: "power",
            title: "Puissance électrique nominale du ou des compresseur(s) en kW",
            type: "number",
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Système de régulation sur un groupe de production de froid permettant d'avoir une haute pression flottante",
    id: "AGRI-UT-104",
    type: "Froid",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-UT-104.pdf",
    fields: [
      {
        id: "type",
        title: "Type de condensation",
        type: "choice",
        choices: [
          {
            name: "Condensation par rapport à l'atmosphère",
            value: ":H:10600:H:10600:H:9700",
          },
          {
            name: "Condensation à eau seule",
            value: ":H:6200:H:6200:H:5400",
          },
        ],
      },
      {
        id: "power2",
        title: "Puissance électrique nominale totale du groupe de production de froid en kW",
        type: "number",
      },
    ],
    attachments: ["Étude technique préalable"],
    startDate: "2024-08-22T01:00:00.000Z",
    history: [
      {
        name: "Système de régulation sur un groupe de production de froid permettant d'avoir une haute pression flottante",
        id: "AGRI-UT-104",
        type: "Froid",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/AGRI-UT-104.pdf",
        fields: [
          {
            id: "type",
            title: "Type de condensation",
            type: "choice",
            choices: [
              {
                name: "Condensation par rapport à l'atmosphère",
                value: ":H:10600:H:10600:H:9700",
              },
              {
                name: "Condensation à eau seule",
                value: ":H:6200:H:6200:H:5400",
              },
            ],
          },
          {
            id: "power",
            title: "Puissance électrique nominale du ou des compresseur(s) en kW",
            type: "number",
          },
        ],
        attachments: ["Étude technique préalable"],
      },
    ],
  },
];

export default agriForms;

export const activeForms: string[] = agriForms.filter((f) => f.fields.length > 0).map((f) => f.id);
