import { Form } from "../formsTypes";

const traForms: Form[] = [
  {
    name: "Unité de transport intermodal pour le transport combiné rail-route",
    id: "TRA-EQ-101",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-101.pdf",
    type: "Véhicule",
    fields: [
      {
        id: "length",
        title: "Longueur de l'UTI",
        type: "choice",
        choices: [
          {
            name: "UTI ≥ 9 m ou UTI < 9 m dont le poids total en charge (PTC) est supérieur à 30 tonnes",
            value: "18500",
          },
          {
            name: "UTI < 9 m dont le PTC est inférieur à 30 tonnes",
            value: "9300",
          },
        ],
      },
      {
        id: "rides",
        title: "Nombre de voyages moyen réalisé sur un an",
        type: "number",
      },
    ],
    attachments: ["Relevé(s) de trafic"],
  },
  {
    name: "Télématique embarquée pour le suivi de la conduite d'un véhicule",
    id: "TRA-EQ-103",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-103.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "category",
        title: "Catégorie des véhicules",
        type: "choice",
        choices: [
          {
            name: "M1",
            value: "1600",
          },
          {
            name: "N1",
            value: "2700",
          },
          {
            name: "N2 et N3",
            value: "27000",
          },
          {
            name: "M2 et M3",
            value: "19900",
          },
        ],
      },
      {
        id: "vehicles",
        title: "Nombre de véhicules équipés",
        type: "number",
      },
    ],
    attachments: ["État récapitulatif pour les véhicules n'étant pas équipés de série"],
  },
  {
    name: "Lubrifiant économiseur d'énergie pour véhicules légers",
    id: "TRA-EQ-104",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-104.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "type",
        title: "Types de lubrifiants pour véhicules de catégorie M1",
        type: "choice",
        choices: [
          {
            name: "Lubrifiants Diesel",
            value: "33",
          },
          {
            name: "Lubrifiants Essence",
            value: "19.4",
          },
          {
            name: "Lubrifiants Mixtes",
            value: "29.1",
          },
        ],
      },
      {
        id: "volume",
        title: "Volume de lubrifiants utilisés, exprimé en litres",
        type: "number",
      },
      {
        id: "performance",
        title: "Performance « Fuel Economy » du lubrifiant, exprimée en % (ex : 2 si 2 %)",
        type: "number",
      },
    ],
    attachments: [
      "Procès verbaux des résultats des tests d'économies de carburant",
      "Fiches techniques des produits",
      "Tableau de correspondance",
      "Preuve du rôle actif et incitatif du demandeur si le bénéficiaire est la personne morale distribuant le lubrifiant",
      "État récapitulatif si le bénéficiaire est la personne morale distribuant le lubrifiant",
    ],
  },
  {
    name: "Pneus de véhicules légers à basse résistance au roulement",
    id: "TRA-EQ-106",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-106.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "nA",
        title: "Nombre de pneumatiques de classe d'efficacité en carburant A",
        skipValue: true,
        type: "number",
      },
      {
        id: "nB",
        title: "Nombre de pneumatiques de classe d'efficacité en carburant B",
        skipValue: true,
        type: "number",
      },
      {
        id: "nC",
        title: "Nombre de pneumatiques de classe d'efficacité en carburant C",
        skipValue: true,
        type: "number",
      },
      {
        id: "mileage",
        title: "Kilométrage annuel moyen parcouru par les véhicules",
        skipValue: true,
        type: "number",
      },
      {
        id: "formula",
        title: "Formule",
        type: "formula",
        value: "TRAEQ106",
      },
    ],
    attachments: ["Document justifiant le kilométrage annuel moyen", "État récapitulatif"],
  },
  {
    name: "Unité de transport intermodal pour le transport combiné fluvial-route",
    id: "TRA-EQ-107",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-107.pdf",
    type: "Véhicule",
    fields: [
      {
        id: "type",
        title: "Type de bateaux",
        type: "choice",
        choices: [
          { name: "Bateau DEK (1 000 t)", value: "1" },
          { name: "Bateau RHK (1 350 t)", value: "2" },
          { name: "Bateau Grand Rhénan (2 500 t)", value: "3" },
          { name: "Bateau Convois (4 400 t)", value: "4" },
        ],
      },
      {
        id: "basin",
        title: "Bassin de navigation",
        type: "choice",
        choices: [
          { name: "Seine", value: "1" },
          { name: "Rhône", value: "2" },
          { name: "Nord Pas-de-Calais", value: "3" },
          { name: "Rhin / Moselle", value: "4" },
          { name: "Interbassin", value: "5" },
        ],
      },
      {
        id: "type|basin",
        title: "Gain net actualisé en kWh cumac de l'UTI selon le bassin de navigation et le type de bateaux",
        type: "multichoice",
        choices: [
          { name: "1|1", value: "3800" },
          { name: "1|2", value: "3200" },
          { name: "1|3", value: "3300" },
          { name: "1|4", value: "1200" },
          { name: "1|5", value: "2900" },
          { name: "2|1", value: "7900" },
          { name: "2|2", value: "7500" },
          { name: "2|3", value: "4000" },
          { name: "2|4", value: "2600" },
          { name: "2|5", value: "5500" },
          { name: "3|1", value: "8500" },
          { name: "3|2", value: "7800" },
          { name: "3|3", value: "4700" },
          { name: "3|4", value: "4100" },
          { name: "3|5", value: "6300" },
          { name: "4|1", value: "9000" },
          { name: "4|2", value: "8500" },
          { name: "4|3", value: "8300" },
          { name: "4|4", value: "6500" },
          { name: "4|5", value: "8000" },
        ],
      },
      {
        id: "travels",
        title: "Nombre de voyages de l'UTI achetée ou louée relevés sur 6 mois x 2",
        type: "number",
      },
    ],
    attachments: ["Relevé(s) de trafic"],
  },
  {
    name: "Wagon d'autoroute ferroviaire",
    id: "TRA-EQ-108",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-108.pdf",
    type: "Véhicule",
    fields: [
      {
        id: "amount",
        title: "Montant de certificats d'économies d'énergie déterminé selon la formule de la fiche (kWhca)",
        type: "number",
      },
    ],
    attachments: [
      "Certificat d'immatriculation du wagon",
      "Autorisation de mise en exploitation commerciale",
      "Relevé(s) de trafic",
      "Tableau de synthèse du ou des relevé(s) de trafic hors ligne Calais - Folkstone",
      "Hors ligne Calais - Folkstone, captures d'écran d'un site internet de calcul des distances routières",
      "Déclaration de la consommation d'énergie et des émissions de gaz à effet de serre",
    ],
  },
  {
    name: "Barge fluviale",
    id: "TRA-EQ-109",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-109.pdf",
    type: "Véhicule",
    fields: [
      {
        id: "ga",
        title: "Bassin de navigation",
        type: "choice",
        choices: [
          { name: "Seine", value: "3.4" },
          { name: "Rhône", value: "3.3" },
          { name: "Nord Pas-de-Calais", value: "3.2" },
          { name: "Rhin / Moselle", value: "2.7" },
          { name: "Interbassin", value: "3.1" },
        ],
      },
      {
        id: "tk",
        title: "t.km effectuées par la barge, relevées sur une période maximale de 6 mois x 2 (t.km réalisées par an)",
        type: "number",
      },
    ],
    attachments: ["Relevé(s) de trafic", "Certificat d'immatriculation de la barge"],
  },
  {
    name: "Automoteur fluvial",
    id: "TRA-EQ-110",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-110.pdf",
    type: "Véhicule",
    fields: [
      {
        id: "type",
        title: "Type d'automoteur",
        type: "choice",
        choices: [
          { name: "Bateau Freycinet (350 t)", value: "1" },
          { name: "Bateau Campinois (600 t)", value: "2" },
          { name: "Bateau DEK (1000 t)", value: "3" },
          { name: "Bateau RHK (1350 t)", value: "4" },
          { name: "Bateau Grand Rhénan (2500 t)", value: "5" },
        ],
      },
      {
        id: "basin",
        title: "Bassin de navigation",
        type: "choice",
        choices: [
          { name: "Seine", value: "1" },
          { name: "Rhône", value: "2" },
          { name: "Nord Pas-de-Calais", value: "3" },
          { name: "Rhin / Moselle", value: "4" },
          { name: "Interbassin", value: "5" },
        ],
      },
      {
        id: "type|basin",
        title: "Gain net actualisé en kWh cumac de l'UTI selon le bassin de navigation et le type de bateaux",
        type: "multichoice",
        choices: [
          { name: "1|1", value: "1.2" },
          { name: "1|2", value: "0.8" },
          { name: "1|3", value: "1.2" },
          { name: "1|4", value: "0.6" },
          { name: "1|5", value: "0.9" },
          { name: "2|1", value: "1.5" },
          { name: "2|2", value: "1.3" },
          { name: "2|3", value: "1.5" },
          { name: "2|4", value: "0.7" },
          { name: "2|5", value: "1.2" },
          { name: "3|1", value: "1.9" },
          { name: "3|2", value: "1.7" },
          { name: "3|3", value: "1.7" },
          { name: "3|4", value: "1.1" },
          { name: "3|5", value: "1.6" },
          { name: "4|1", value: "3.1" },
          { name: "4|2", value: "3.0" },
          { name: "4|3", value: "2.0" },
          { name: "4|4", value: "1.5" },
          { name: "4|5", value: "2.5" },
          { name: "5|1", value: "3.3" },
          { name: "5|2", value: "3.1" },
          { name: "5|3", value: "2.2" },
          { name: "5|4", value: "2.0" },
          { name: "5|5", value: "2.7" },
        ],
      },
      {
        id: "travels",
        title:
          "t.km effectuées par l'automoteur et relevées sur une période maximale de 6 mois x 2 (t.km réalisées par an)",
        type: "number",
      },
    ],
    attachments: ["Relevé(s) de trafic", "Copie du titre de navigation"],
  },
  {
    name: "Groupe frigorifique autonome à haute efficacité énergétique pour camions, semi-remorques, remorques et caisse mobiles frigorifiques",
    id: "TRA-EQ-111",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-111.pdf",
    type: "Équipement",
    fields: [
      {
        id: "yield",
        title: "Rendement global (Rg) pondéré",
        skipValue: true,
        type: "number",
        minValue: 1.93,
      },
      {
        id: "formula",
        title: "Formule",
        type: "formula",
        value: "TRAEQ111",
      },
    ],
    attachments: [
      "Attestation indiquant les valeurs du rendement global (Rg) et les références des rapports d'essais",
      "Certificat d'immatriculation du véhicule équipé",
    ],
  },
  {
    name: "Lubrifiant économiseur d'énergie pour des véhicules de transport de personnes ou de marchandises",
    id: "TRA-EQ-113",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-113.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "ratio",
        title: "Montant de certificats en kWh cumac",
        type: "value",
        value: "38700",
      },
      {
        id: "volume",
        title: "Volume de lubrifiant utilisé en m³",
        type: "number",
      },
      {
        id: "performance",
        title: "Performance « Fuel Economy » du lubrifiant, exprimée en % (ex : 2 si 2 %)",
        type: "number",
      },
    ],
    attachments: [
      "Procès verbaux des résultats des tests d'économies de carburant",
      "Si la désignation des lubrifiants dans la preuve de réalisation est différente de celle des formulations testées, un tableau de correspondance",
      "État récapitulatif",
    ],
  },
  {
    name: "Achat ou location d’un véhicule léger ou véhicule utilitaire léger électrique neuf ou opération de rétrofit électrique d’un véhicule léger ou véhicule utilitaire léger, par une collectivité locale ou une autre personne morale",
    id: "TRA-EQ-114",
    formUrl: "https://gdceeforms.blob.core.windows.net/v65/TRA-EQ-114.pdf",
    type: "Véhicule",
    fields: [
      {
        id: "society",
        title: "Catégorie de bénéficiaire",
        type: "choice",
        choices: [
          {
            name: "Personne morale gérant un parc total ou filiale d'un groupe gérant un parc total supérieur à 100 véhicules automobiles",
            value: "2",
          },
          { name: "Collectivité locale gérant un parc supérieur à 20 véhicules automobiles", value: "3" },
          { name: "Autre", value: "1" },
        ],
      },
      {
        id: "type",
        title: "Type de véhicule",
        type: "choice",
        choices: [
          { name: "Véhicule léger neuf", value: "1" },
          { name: "Véhicule utilitaire léger neuf", value: "2" },
          { name: "Véhicule léger issu d’une opération de rétrofit", value: "3" },
          { name: "Véhicule utilitaire léger issu d’une opération de rétrofit", value: "4" },
        ],
      },
      {
        id: "number",
        title: "Nombre de véhicules",
        type: "number",
      },
      {
        id: "society|type",
        title: "Montant en kWh cumac par véhicule",
        type: "multichoice",
        choices: [
          { name: "1|1", value: "74200" },
          { name: "1|2", value: "156800" },
          { name: "1|3", value: "59800" },
          { name: "1|4", value: "126300" },

          // valable pour 2025 et 2026
          { name: "2|1", value: "59400" },
          { name: "2|2", value: "125400" },
          { name: "2|3", value: "47800" },
          { name: "2|4", value: "101100" },
          // valable à partir de 2027
          // { name: "2|1", value: "44500" },
          // { name: "2|2", value: "94100" },
          // { name: "2|3", value: "35900" },
          // { name: "2|4", value: "75800" },

          { name: "3|1", value: "44500" },
          { name: "3|2", value: "94100" },
          { name: "3|3", value: "35900" },
          { name: "3|4", value: "75800" },
        ],
      },
    ],
    attachments: ["Copie du (des) certificat(s) d'immatriculation", "Feuille récapitulative"],
    startDate: "2025-01-01T02:00:00.000Z",
    history: [
      {
        name: "Remplacement de véhicules par des véhicules neufs performants dans une flotte professionnelle",
        id: "TRA-EQ-114",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-114.pdf",
        type: "Véhicule",
        fields: [
          {
            id: "co2Emission",
            title: "Moyenne des émissions de CO2 en gCO2/km des véhicules acquis ou loués dans le cadre de l'opération",
            skipValue: true,
            type: "number",
            maxValue: 116,
          },
          {
            id: "quantity",
            title: "Nombre de véhicules de catégorie M1",
            skipValue: true,
            type: "number",
          },
          {
            id: "formula",
            title: "Formule",
            type: "formula",
            value: "TRAEQ114",
          },
        ],
        attachments: ["Feuille de calcul décrivant les cessions et acquisitions"],
      },
    ],
  },
  {
    name: "Véhicule de transport de marchandises optimisé",
    id: "TRA-EQ-115",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-115.pdf",
    type: "Véhicule",
    fields: [
      {
        id: "ratio",
        title: "Montant en kWh cumac par véhicule neuf optimisé",
        type: "value",
        value: "181300",
      },
      {
        id: "vehicles",
        title: "Nombre de véhicules neufs optimisés",
        type: "number",
      },
    ],
    attachments: ["Copie du certificat d'immatriculation du véhicule optimisé", "État récapitulatif"],
  },
  {
    name: "Remplacement de véhicules par des véhicules neufs performants pour les particuliers ou les collectivités",
    id: "TRA-EQ-117",
    formUrl: "https://gdceeforms.blob.core.windows.net/v65/TRA-EQ-117.pdf",
    type: "Véhicule",
    fields: [
      {
        id: "type",
        title: "Catégorie du véhicule",
        type: "choice",
        choices: [
          { name: "Véhicule léger neuf", value: "1" },
          { name: "Véhicule utilitaire léger neuf", value: "2" },
          { name: "Véhicule léger issu d’une opération de rétrofit", value: "3" },
          { name: "Véhicule utilitaire léger issu d’une opération de rétrofit", value: "4" },
        ],
      },
      {
        id: "type",
        title: "Montant",
        type: "multichoice",
        choices: [
          { name: "1", value: "49100" },
          { name: "2", value: "94800" },
          { name: "3", value: "39500" },
          { name: "4", value: "76400" },
        ],
      },
    ],
    attachments: ["Certificat d'immatriculation"],
    startDate: "2025-01-01T02:00:00.000Z",
    history: [
      {
        name: "Remplacement de véhicules par des véhicules neufs performants pour les particuliers ou les collectivités",
        id: "TRA-EQ-117",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-117.pdf",
        type: "Véhicule",
        fields: [
          {
            id: "type",
            title: "Type de déclaration",
            type: "choice",
            skipValue: true,
            choices: [
              { name: "Déclaration par véhicule", value: "1" },
              { name: "Déclaration groupée", value: "2" },
            ],
          },
          {
            id: "co2Emission",
            title:
              "Émission de CO2 en gCO2/km du véhicule de catégorie M1 acquis indiquée sur le certificat d'immatriculation",
            type: "number",
            skipValue: true,
            showIf: { fieldId: "type", fieldValues: ["1"] },
            maxValue: 116,
          },
          {
            id: "co2EmissionG",
            title: "Moyenne des émissions de CO2 en gCO2/km des véhicules acquis ou loués dans le cadre de l'opération",
            type: "number",
            skipValue: true,
            showIf: { fieldId: "type", fieldValues: ["2"] },
            maxValue: 116,
          },
          {
            id: "quantity",
            title: "Nombre de véhicules de catégorie M1",
            type: "number",
            skipValue: true,
            showIf: { fieldId: "type", fieldValues: ["2"] },
          },
          {
            id: "formula",
            title: "Formule",
            type: "formula",
            value: "TRAEQ117",
          },
        ],
        attachments: [
          "Copie barrée de chaque certificat d'immatriculation",
          "Copie du ou des nouveaux(s) certificat(s) d'immatriculation(s)",
          "Feuille de calcul décrivant les cessions et acquisitions correspondantes",
        ],
        attachmentsShowIf: [{ attachmentId: 2, condition: { fieldId: "type", fieldValues: ["2"] } }],
      },
    ],
  },
  {
    name: "Lubrifiant économiseur d'énergie pour la pêche professionnelle",
    id: "TRA-EQ-118",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-118.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "amount",
        title: "Montant de certificats d'économies d'énergie déterminé selon la formule de la fiche (kWhca)",
        type: "number",
      },
    ],
    attachments: ["Copie de l'acte de francisation du navire", "État récapitulatif", "Rapport de test"],
  },
  {
    name: "Optimisation de la combustion et de la propreté des moteurs Diesel",
    id: "TRA-EQ-119",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-119.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "type",
        skipValue: true,
        title: "Type d'acquisition",
        type: "choice",
        choices: [
          {
            name: "Auxiliaire d'optimisation de la combustion",
            value: "1",
          },
          {
            name: "Carburant traité avec un auxiliaire d'optimisation de la combustion",
            value: "2",
          },
        ],
      },
      {
        id: "x",
        skipValue: true,
        title: "Volume d'auxiliaire d'optimisation de la combustion utilisé (m³)",
        type: "number",
        showIf: {
          fieldId: "type",
          fieldValues: ["1"],
        },
      },
      {
        id: "z",
        skipValue: true,
        title:
          "Concentration de la solution de traitement utilisée figurant sur la preuve de réalisation de l'opération",
        type: "number",
        showIf: {
          fieldId: "type",
          fieldValues: ["1"],
        },
      },
      {
        id: "w",
        skipValue: true,
        title: "Volume de carburant traité avec un auxiliaire d'optimisation de la combustion utilisé (m³)",
        type: "number",
        showIf: {
          fieldId: "type",
          fieldValues: ["2"],
        },
      },
      {
        id: "y",
        skipValue: true,
        title:
          "Gain de consommation de carburant associé à l'utilisation de l'auxiliaire de combustion (ex : 0.03 si 3 %)",
        type: "number",
      },
      {
        id: "formula",
        type: "formula",
        title: "Calcul",
        value: "TRAEQ119",
      },
    ],
    attachments: ["Copie du rapport d'essai", "État récapitulatif"],
  },
  {
    name: "Hélice avec tuyère sur une unité de transport fluvial",
    id: "TRA-EQ-120",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-120.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "type",
        title: "Type d'unité de transport fluviale",
        type: "choice",
        skipValue: true,
        choices: [
          { name: "Automoteur", value: "1" },
          { name: "Pousseur", value: "2" },
        ],
      },
      {
        id: "basin",
        title: "Bassin de navigation",
        type: "choice",
        choices: [
          { name: "Seine", value: "1" },
          { name: "Rhône", value: "2" },
          { name: "Nord Pas-de-Calais", value: "3" },
          { name: "Rhin / Moselle", value: "4" },
          { name: "Interbassin", value: "5" },
        ],
      },
      {
        id: "weight",
        title: "Gamme de port en lourd M (tonne)",
        type: "choice",
        showIf: { fieldId: "type", fieldValues: ["1"] },
        choices: [
          { name: "250 t < M ≤ 400 t", value: "1" },
          { name: "400 < M ≤ 650 t", value: "2" },
          { name: "650 < M ≤ 1 000 t", value: "3" },
          { name: "1 000 < M ≤ 1 500 t", value: "4" },
          { name: "1 500 t < M", value: "5" },
        ],
      },
      {
        id: "weight|basin",
        title: "Gain Ga en kWh cumac/t.km selon la capacité de chargement de l'automoteur et le bassin de navigation",
        type: "multichoice",
        showIf: { fieldId: "type", fieldValues: ["1"] },
        choices: [
          { name: "1|1", value: "0.93" },
          { name: "1|2", value: "1.06" },
          { name: "1|3", value: "0.94" },
          { name: "1|4", value: "1.11" },
          { name: "1|5", value: "1.02" },
          { name: "2|1", value: "0.86" },
          { name: "2|2", value: "0.93" },
          { name: "2|3", value: "0.87" },
          { name: "2|4", value: "1.10" },
          { name: "2|5", value: "0.95" },
          { name: "3|1", value: "0.75" },
          { name: "3|2", value: "0.80" },
          { name: "3|3", value: "0.80" },
          { name: "3|4", value: "0.97" },
          { name: "3|5", value: "0.83" },
          { name: "4|1", value: "0.41" },
          { name: "4|2", value: "0.45" },
          { name: "4|3", value: "0.73" },
          { name: "4|4", value: "0.85" },
          { name: "4|5", value: "0.59" },
          { name: "5|1", value: "0.37" },
          { name: "5|2", value: "0.42" },
          { name: "5|3", value: "0.68" },
          { name: "5|4", value: "0.73" },
          { name: "5|5", value: "0.52" },
        ],
      },
      {
        id: "tk",
        title: "t.km réalisées par an",
        showIf: { fieldId: "type", fieldValues: ["1"] },
        type: "number",
      },
      {
        id: "power",
        title: "Gamme en puissance P (kW)",
        type: "choice",
        showIf: { fieldId: "type", fieldValues: ["2"] },
        choices: [
          { name: "295 ≤ P ≤ 590", value: "1" },
          { name: "590 < P ≤ 880", value: "2" },
          { name: "880 < P", value: "3" },
        ],
      },
      {
        id: "power|basin",
        title: "Gain Ga en kWh cumac par km selon la puissance du pousseur et le bassin de navigation",
        type: "multichoice",
        showIf: { fieldId: "type", fieldValues: ["2"] },
        choices: [
          { name: "1|1", value: "460" },
          { name: "1|2", value: "490" },
          { name: "1|3", value: "360" },
          { name: "1|4", value: "0" },
          { name: "1|5", value: "440" },
          { name: "2|1", value: "710" },
          { name: "2|2", value: "750" },
          { name: "2|3", value: "500" },
          { name: "2|4", value: "0" },
          { name: "2|5", value: "650" },
          { name: "3|1", value: "900" },
          { name: "3|2", value: "870" },
          { name: "3|3", value: "700" },
          { name: "3|4", value: "1500" },
          { name: "3|5", value: "980" },
        ],
      },
      {
        id: "mileage",
        title: "Kilométrage réalisé par an",
        showIf: { fieldId: "type", fieldValues: ["2"] },
        type: "number",
      },
    ],
    attachments: ["Relevé de trafic", "Copie du titre de navigation"],
  },
  {
    name: "Vélo à assistance électrique",
    id: "TRA-EQ-121",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-121.pdf",
    type: "Équipement",
    fields: [
      {
        id: "type",
        title: "Achat / location",
        type: "choice",
        choices: [
          {
            name: "Achat d'un cycle neuf",
            value: "8700",
          },
          {
            name: "Location d'un cycle neuf",
            value: "4000",
          },
        ],
      },
    ],
    attachments: [
      "certificat d'homologation attestant la conformité à la norme NF EN 15194",
      "preuve d'enregistrement au fichier national unique des cycles identifiés (FNUCI)",
    ],
  },
  {
    name: "« Stop & Start » pour engins automoteurs non routiers neufs",
    id: "TRA-EQ-122",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-122.pdf",
    type: "Équipement",
    fields: [
      {
        id: "quantity",
        title: "Nombre d'engins automoteurs non routiers concernés par l'opération",
        type: "number",
      },
      {
        id: "amount",
        title: "Montant en kWh cumac",
        type: "value",
        value: "72900 ",
      },
    ],
    attachments: ["Photographie lisible de la plaque constructeur d'identification de l'engin"],
  },
  {
    name: "Simulateur de conduite",
    id: "TRA-EQ-123",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-123.pdf",
    type: "Chauffeur",
    fields: [
      {
        id: "type",
        title: "Type de véhicule",
        type: "choice",
        choices: [
          { name: "Véhicules de catégories M1 et N1", value: "87400" },
          { name: "Autres véhicules (catégories M2, M3, N2 et N3)", value: "39000" },
        ],
      },
      {
        id: "quantity",
        title: "Nombre de simulateurs",
        type: "number",
      },
    ],
    attachments: [
      "Document issu du fabricant décrivant les fonctions du simulateur",
      "Copie de l'agrément accordé à l'organisme de formation",
    ],
  },
  {
    name: "Branchement électrique des navires et bateaux à quai",
    id: "TRA-EQ-124",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-124.pdf",
    type: "Équipement",
    fields: [
      {
        id: "type",
        title: "Type de port",
        type: "choice",
        choices: [
          { name: "Maritime", value: "13.4" },
          { name: "Fluvial", value: "45" },
        ],
      },
      {
        id: "consumption",
        title: "Consommation en kWh par infrastructure d'alimentation électrique sur 6 mois maximum",
        type: "number",
      },
    ],
    attachments: ["Relevé de consommation d'électricité", "Factures de distribution d'électricité"],
  },
  {
    name: "« Stop & Start » pour véhicules ferroviaires",
    id: "TRA-EQ-125",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-125.pdf",
    type: "Équipement",
    fields: [
      {
        id: "type",
        title: "Type de véhicule ferroviaire",
        type: "choice",
        choices: [
          { name: "Fret", value: "800" },
          { name: "Travaux sur voies", value: "1800" },
          { name: "Manœuvres/triage", value: "950" },
        ],
      },
      {
        id: "duration",
        title: "Nombre total d'heures de fonctionnement du moteur du véhicule ferroviaire indiqué dans le relevé",
        type: "number",
      },
    ],
    attachments: [
      "Photographie lisible de la plaque constructeur du système « Stop & Start »",
      "Relevé du nombre total d'heures de fonctionnement du système « Stop & Start » actif et du nombre total d'heures de fonctionnement du moteur du véhicule ferroviaire, sur le territoire français",
    ],
  },
  {
    name: "Remotorisation en propulsion électrique ou hybride d'un bateau naviguant en eaux intérieures",
    id: "TRA-EQ-126",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-126.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "type",
        title: "Type de bateau",
        type: "choice",
        choices: [
          { name: "Bateau de réception destiné à un usage principalement privatif et bateau de travail", value: "1" },
          { name: "Petit bateau à passagers", value: "2" },
          {
            name: "Bateau restaurant, bateau promenades, automoteur, bateau de plaisance et péniche-hôtel",
            value: "3",
          },
        ],
      },
      {
        id: "motor",
        title: "Type de motorisation initiale",
        type: "choice",
        choices: [
          { name: "Bateau initialement thermique", value: "1" },
          { name: "Bateau initialement diesel-électrique", value: "2" },
        ],
      },
      {
        id: "motor|type",
        title: "Montant en kWh cumac par bateau",
        type: "multichoice",
        choices: [
          { name: "1|1", value: "147.567" },
          { name: "1|2", value: "103.285" },
          { name: "1|3", value: "71.655" },
          { name: "2|1", value: "54.024" },
          { name: "2|2", value: "40.809" },
          { name: "2|3", value: "29.212" },
        ],
      },
      {
        id: "duration",
        title: "Nombre total d'heures d'utilisation du bateau indiqué dans le relevé",
        type: "number",
      },
      {
        id: "power",
        title: "Puissance de la motorisation initiale remplacée (en kW)",
        type: "number",
      },
    ],
    attachments: ["Relevé du nombre total d'heures d'utilisation", "Preuve du type de bateaux concerné"],
  },
  {
    name: "Acquisition d'un bateau neuf à propulsion électrique ou hybride, naviguant en eaux intérieures",
    id: "TRA-EQ-127",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-127.pdf",
    type: "Véhicule",
    fields: [
      {
        id: "motor",
        title: "Type de motorisation",
        type: "choice",
        choices: [
          { name: "100% électrique", value: "1" },
          { name: "Hybride - série", value: "2" },
          { name: "Hybride - parallèle", value: "3" },
        ],
      },
      {
        id: "type",
        title: "Type de bateau",
        type: "choice",
        choices: [
          { name: "Bateau de réception et bateau de travail", value: "1" },
          { name: "Petit bateau à passagers", value: "2" },
          {
            name: "Bateau restaurant, bateau promenade, automoteur, bateau de croisière fluviale avec hébergement et péniche-hôtel",
            value: "3",
          },
          { name: "Bateau de plaisance", value: "4" },
        ],
      },
      {
        id: "motor|type",
        title: "Montant en kWh cumac",
        type: "multichoice",
        choices: [
          { name: "1|1", value: "121.59" },
          { name: "1|2", value: "79.21" },
          { name: "1|3", value: "43.85" },
          { name: "1|4", value: "31.96" },
          { name: "2|1", value: "113.26" },
          { name: "2|2", value: "70.88" },
          { name: "2|3", value: "35.52" },
          { name: "2|4", value: "23.63" },
          { name: "3|1", value: "59.66" },
          { name: "3|2", value: "38.69" },
          { name: "3|3", value: "21.19" },
          { name: "3|4", value: "15.3" },
        ],
      },
      {
        id: "duration",
        title: "Nombre total d'heures relevées sur la période d'utilisation du bateau",
        type: "number",
      },
      {
        id: "power",
        title: "Puissance totale de la motorisation principale (en kW)",
        type: "number",
      },
    ],
    attachments: [
      "Facture d'acquisition ou le contrat de location du bateau",
      "Preuve de la catégorie de bateau concernée",
      "Document établi par le bénéficiaire du bateau attestant de la valeur du taux d'hybridation",
    ],
    attachmentsShowIf: [{ attachmentId: 2, condition: { fieldId: "motor", fieldValues: ["2", "3"] } }],
  },
  {
    name: "Achat ou location d'un autocar ou d'un autobus électrique neuf ou réalisation d'une opération de rétrofit électrique d'autocar ou d'autobus",
    id: "TRA-EQ-128",
    formUrl: "https://gdceeforms.blob.core.windows.net/v65/TRA-EQ-128.pdf",
    type: "Véhicule",
    fields: [
      {
        id: "type",
        title: "Type de véhicule",
        type: "choice",
        skipValue: true,
        choices: [
          { name: "Autocar", value: "1" },
          { name: "Autobus", value: "2" },
        ],
      },
      {
        id: "category",
        title: "Catégorie du (des) véhicule(s)",
        type: "choice",
        skipValue: true,
        choices: [
          { name: "Autocar issu d'une opération de rétrofit", value: "1" },
          { name: "Autocar standard", value: "2" },
          { name: "Autocar grande capacité", value: "3" },
        ],
        showIf: { fieldId: "type", fieldValues: ["1"] },
      },
      {
        id: "category",
        title: "Gisement par autocar",
        type: "multichoice",
        choices: [
          { name: "1", value: "1049900" },
          { name: "2", value: "1602800" },
          { name: "3", value: "2564500" },
        ],
        showIf: { fieldId: "type", fieldValues: ["1"] },
      },
      {
        id: "category",
        title: "Catégorie du (des) véhicule(s)",
        type: "choice",
        skipValue: true,
        choices: [
          { name: "Autobus issu d'une opération de rétrofit", value: "1" },
          { name: "Autobus standard", value: "2" },
          { name: "Autobus grande capacité", value: "3" },
        ],
        showIf: { fieldId: "type", fieldValues: ["2"] },
      },
      {
        id: "citySize",
        title: "Type d'agglomération",
        type: "choice",
        skipValue: true,
        choices: [
          { name: "Agglomération ≤ 250 000 habitants", value: "1" },
          { name: "Agglomération > 250 000 habitants", value: "2" },
        ],
        showIf: { fieldId: "type", fieldValues: ["2"] },
      },
      {
        id: "category|citySize",
        title: "Gisement par autobus",
        type: "multichoice",
        choices: [
          { name: "1|1", value: "1538500" },
          { name: "2|1", value: "2350700" },
          { name: "3|1", value: "3291000" },
          { name: "1|2", value: "769200" },
          { name: "2|2", value: "1175300" },
          { name: "3|2", value: "1645500" },
        ],
        showIf: { fieldId: "type", fieldValues: ["2"] },
      },
      {
        id: "number",
        title: "Nombre de véhicules",
        type: "number",
      },
    ],
    attachments: ["Certificats d'immatriculation du (des) véhicule(s)", "Feuille récapitulative"],
    startDate: "2025-01-01T02:00:00.000Z",
    history: [
      {
        name: "Achat ou location d'un autocar ou d'un autobus électrique neuf ou réalisation d'une opération de rétrofit électrique d'autocar ou d'autobus",
        id: "TRA-EQ-128",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-EQ-128.pdf",
        type: "Véhicule",
        fields: [
          {
            id: "type",
            title: "Type de véhicule",
            type: "choice",
            skipValue: true,
            choices: [
              { name: "Autocar", value: "1" },
              { name: "Autobus", value: "2" },
            ],
          },
          {
            id: "category",
            title: "Catégorie du (des) véhicule(s)",
            type: "choice",
            skipValue: true,
            choices: [
              { name: "Autocar issu d'une opération de rétrofit", value: "1" },
              { name: "Autocar standard", value: "2" },
              { name: "Autocar grande capacité", value: "3" },
            ],
            showIf: { fieldId: "type", fieldValues: ["1"] },
          },
          {
            id: "category",
            title: "Gisement par autocar",
            type: "multichoice",
            choices: [
              { name: "1", value: "582800" },
              { name: "2", value: "889100" },
              { name: "3", value: "1422500" },
            ],
            showIf: { fieldId: "type", fieldValues: ["1"] },
          },
          {
            id: "category",
            title: "Catégorie du (des) véhicule(s)",
            type: "choice",
            skipValue: true,
            choices: [
              { name: "Autobus issu d'une opération de rétrofit", value: "1" },
              { name: "Autobus standard", value: "2" },
              { name: "Autobus grande capacité", value: "3" },
            ],
            showIf: { fieldId: "type", fieldValues: ["2"] },
          },
          {
            id: "citySize",
            title: "Type d'agglomération",
            type: "choice",
            skipValue: true,
            choices: [
              { name: "Agglomération ≤ 250 000 habitants", value: "1" },
              { name: "Agglomération > 250 000 habitants", value: "2" },
            ],
            showIf: { fieldId: "type", fieldValues: ["2"] },
          },
          {
            id: "category|citySize",
            title: "Gisement par autobus",
            type: "multichoice",
            choices: [
              { name: "1|1", value: "627600" },
              { name: "2|1", value: "958900" },
              { name: "3|1", value: "1342500" },
              { name: "1|2", value: "470700" },
              { name: "2|2", value: "719200" },
              { name: "3|2", value: "1006900" },
              /* From 01/01/2205, update those values:
              { name: "1|2", value: "313800" },
              { name: "2|2", value: "479500" },
              { name: "3|2", value: "671200" },
              */
            ],
            showIf: { fieldId: "type", fieldValues: ["2"] },
          },
          {
            id: "number",
            title: "Nombre de véhicules",
            type: "number",
          },
        ],
        attachments: ["Certificats d'immatriculation du (des) véhicule(s)", "Feuille récapitulative"],
      },
    ],
  },
  {
    name: "Formation d'un chauffeur de transport à la conduite économe",
    id: "TRA-SE-101",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-SE-101.pdf",
    type: "Chauffeur",
    fields: [
      {
        id: "type",
        title: "Type de formation",
        type: "choice",
        choices: [
          {
            name: "Véhicules destinés au transport de marchandises de catégories N2 ou N3",
            value: "12400",
          },
          {
            name: "Véhicules destinés au transport de personnes de catégories M2 ou M3",
            value: "9100",
          },
        ],
      },
      {
        id: "people",
        title: "Nombre de personnes formées",
        type: "number",
      },
    ],
    attachments: [
      "Descriptif des modules de la formation",
      "Accusé de réception de la déclaration d'existence de l'organisme de formation",
      "État récapitulatif",
    ],
  },
  {
    name: "Formation d'un chauffeur de véhicule léger à la conduite économe",
    id: "TRA-SE-102",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-SE-102.pdf",
    type: "Chauffeur",
    fields: [
      {
        id: "type",
        title: "Type de formation",
        type: "choice",
        choices: [
          {
            name: "Véhicules de catégorie M1",
            value: "2900",
          },
          {
            name: "Véhicules de catégorie N1",
            value: "2400",
          },
        ],
      },
      {
        id: "people",
        title: "Nombre de personnes formées",
        type: "number",
      },
    ],
    attachments: [
      "Descriptif des modules de la formation",
      "Accusé de réception de la déclaration d'existence de l'organisme de formation",
      "État récapitulatif",
    ],
  },
  {
    name: "Station de gonflage des pneumatiques",
    id: "TRA-SE-104",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-SE-104.pdf",
    type: "Service",
    fields: [
      {
        id: "type",
        title: "Type de station",
        type: "choice",
        choices: [
          { name: "Type A", value: "534200" },
          { name: "Type B", value: "148400" },
          { name: "Type C", value: "39600" },
        ],
      },
      {
        id: "stations",
        title: "Nombre de stations de gonflage",
        type: "number",
      },
    ],
    attachments: ["Procédure de contrôle quotidien", "État récapitulatif"],
  },
  {
    name: "Recreusage des pneumatiques",
    id: "TRA-SE-105",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-SE-105.pdf",
    type: "Service",
    fields: [
      {
        id: "amount",
        title: "Montant en kWh cumac par pneumatique recreusé",
        type: "value",
        value: "360",
      },
      {
        id: "quantity",
        title: "Nombre de pneumatiques recreusés",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Mesure et optimisation des consommations de carburant d'une unité de transport fluvial",
    id: "TRA-SE-106",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-SE-106.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "type",
        title: "Type d'unité de transport fluviale",
        type: "choice",
        skipValue: true,
        choices: [
          { name: "Automoteur", value: "1" },
          { name: "Pousseur", value: "2" },
        ],
      },
      {
        id: "basin",
        title: "Bassin de navigation",
        type: "choice",
        choices: [
          { name: "Seine", value: "1" },
          { name: "Rhône", value: "2" },
          { name: "Nord Pas-de-Calais", value: "3" },
          { name: "Rhin / Moselle", value: "4" },
          { name: "Interbassin", value: "5" },
        ],
      },
      {
        id: "weight",
        title: "Gamme de port en lourd (tonne)",
        type: "choice",
        showIf: { fieldId: "type", fieldValues: ["1"] },
        choices: [
          { name: "M ≤ 400 t", value: "1" },
          { name: "400 < M ≤ 650 t", value: "2" },
          { name: "650 < M ≤ 1 000 t", value: "3" },
          { name: "1 000 < M ≤ 1 500 t", value: "4" },
          { name: "1 500 t < M", value: "5" },
        ],
      },
      {
        id: "weight|basin",
        title: "Gain Ga en kWh cumac/t.km selon la capacité de chargement de l'automoteur et le bassin de navigation",
        type: "multichoice",
        showIf: { fieldId: "type", fieldValues: ["1"] },
        choices: [
          { name: "1|1", value: "0.80" },
          { name: "1|2", value: "0.91" },
          { name: "1|3", value: "0.81" },
          { name: "1|4", value: "0.95" },
          { name: "1|5", value: "0.87" },
          { name: "2|1", value: "0.74" },
          { name: "2|2", value: "0.80" },
          { name: "2|3", value: "0.74" },
          { name: "2|4", value: "0.94" },
          { name: "2|5", value: "0.81" },
          { name: "3|1", value: "0.65" },
          { name: "3|2", value: "0.69" },
          { name: "3|3", value: "0.68" },
          { name: "3|4", value: "0.83" },
          { name: "3|5", value: "0.71" },
          { name: "4|1", value: "0.36" },
          { name: "4|2", value: "0.38" },
          { name: "4|3", value: "0.63" },
          { name: "4|4", value: "0.73" },
          { name: "4|5", value: "0.52" },
          { name: "5|1", value: "0.32" },
          { name: "5|2", value: "0.36" },
          { name: "5|3", value: "0.58" },
          { name: "5|4", value: "0.63" },
          { name: "5|5", value: "0.47" },
        ],
      },
      {
        id: "tk",
        title: "t.km relevées sur une période maximale de 6 mois x 2",
        showIf: { fieldId: "type", fieldValues: ["1"] },
        type: "number",
      },
      {
        id: "power",
        title: "Gamme en puissance P (kW)",
        type: "choice",
        showIf: { fieldId: "type", fieldValues: ["2"] },
        choices: [
          { name: "295 kW ≤ P ≤ 590 kW", value: "1" },
          { name: "590 kW < P ≤ 880 kW", value: "2" },
          { name: "880 kW < P", value: "3" },
        ],
      },
      {
        id: "power|basin",
        title: "Gain Ga en kWh cumac par km selon la puissance du pousseur et le bassin de navigation",
        type: "multichoice",
        showIf: { fieldId: "type", fieldValues: ["2"] },
        choices: [
          { name: "1|1", value: "400" },
          { name: "1|2", value: "420" },
          { name: "1|3", value: "310" },
          { name: "1|4", value: "0" },
          { name: "1|5", value: "370" },
          { name: "2|1", value: "610" },
          { name: "2|2", value: "650" },
          { name: "2|3", value: "430" },
          { name: "2|4", value: "0" },
          { name: "2|5", value: "560" },
          { name: "3|1", value: "770" },
          { name: "3|2", value: "750" },
          { name: "3|3", value: "600" },
          { name: "3|4", value: "1300" },
          { name: "3|5", value: "840" },
        ],
      },
      {
        id: "y",
        title: "Type d'équipement",
        type: "choice",
        choices: [
          { name: "Débitmètre ou économètre", value: "0.05" },
          { name: "Débitmètre ou économètre + ensemble loch, anémomètre, GPS, compte-tours et sondeur", value: "0.07" },
        ],
      },
      {
        id: "mileage",
        title: "Kilométrage réalisé sur une période maximale de 6 mois consécutifs x 2",
        showIf: { fieldId: "type", fieldValues: ["2"] },
        type: "number",
      },
    ],
    attachments: ["Relevé de trafic", "Copie du titre de navigation"],
  },
  {
    name: "Carénage sur une unité de transport fluvial",
    id: "TRA-SE-107",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-SE-107.pdf",
    type: "Équipement",
    fields: [
      {
        id: "type",
        title: "Type d'unité de transport fluviale",
        type: "choice",
        skipValue: true,
        choices: [
          { name: "Automoteur", value: "1" },
          { name: "Barge fluviale", value: "2" },
          { name: "Pousseur", value: "3" },
        ],
      },
      {
        id: "basin",
        title: "Bassin de navigation",
        type: "choice",
        choices: [
          { name: "Seine", value: "1" },
          { name: "Rhône", value: "2" },
          { name: "Nord / Pas-de-Calais", value: "3" },
          { name: "Rhin / Moselle", value: "4" },
          { name: "Interbassin", value: "5" },
        ],
      },
      {
        id: "weight",
        title: "Gamme de port en lourd M (tonne)",
        type: "choice",
        showIf: { fieldId: "type", fieldValues: ["1"] },
        choices: [
          { name: "250 t < M ≤ 400 t", value: "1" },
          { name: "400 < M ≤ 650 t", value: "2" },
          { name: "650 < M ≤ 1 000 t", value: "3" },
          { name: "1 000 < M ≤ 1 500 t", value: "4" },
          { name: "1 500 t < M", value: "5" },
        ],
      },
      {
        id: "weight|basin",
        title: "Gain Ga en kWh cumac/t.km selon la capacité de chargement de l'automoteur et le bassin de navigation",
        type: "multichoice",
        showIf: { fieldId: "type", fieldValues: ["1"] },
        choices: [
          { name: "1|1", value: "0.025" },
          { name: "1|2", value: "0.028" },
          { name: "1|3", value: "0.025" },
          { name: "1|4", value: "0.030" },
          { name: "1|5", value: "0.027" },
          { name: "2|1", value: "0.023" },
          { name: "2|2", value: "0.025" },
          { name: "2|3", value: "0.023" },
          { name: "2|4", value: "0.029" },
          { name: "2|5", value: "0.025" },
          { name: "3|1", value: "0.020" },
          { name: "3|2", value: "0.021" },
          { name: "3|3", value: "0.021" },
          { name: "3|4", value: "0.026" },
          { name: "3|5", value: "0.022" },
          { name: "4|1", value: "0.011" },
          { name: "4|2", value: "0.012" },
          { name: "4|3", value: "0.020" },
          { name: "4|4", value: "0.023" },
          { name: "4|5", value: "0.016" },
          { name: "5|1", value: "0.009" },
          { name: "5|2", value: "0.011" },
          { name: "5|3", value: "0.018" },
          { name: "5|4", value: "0.019" },
          { name: "5|5", value: "0.014" },
        ],
      },
      {
        id: "basin",
        title: "Bassin de navigation",
        type: "multichoice",
        showIf: { fieldId: "type", fieldValues: ["2"] },
        choices: [
          { name: "1", value: "0.009" },
          { name: "2", value: "0.01" },
          { name: "3", value: "0.01" },
          { name: "4", value: "0.014" },
          { name: "5", value: "0.011" },
        ],
      },
      {
        id: "tk",
        title: "t.km réalisées sur une période maximale de 6 mois à compter du carénage x 2",
        showIf: { fieldId: "type", fieldValues: ["1", "2"] },
        type: "number",
      },
      {
        id: "power",
        title: "Gamme en puissance P (kW)",
        type: "choice",
        showIf: { fieldId: "type", fieldValues: ["3"] },
        choices: [
          { name: "295 ≤ P ≤ 590", value: "1" },
          { name: "590 < P ≤ 880", value: "2" },
          { name: "880 < P", value: "3" },
        ],
      },
      {
        id: "power|basin",
        title: "Gain Ga en kWh cumac par km selon la puissance du pousseur et le bassin de navigation",
        type: "multichoice",
        showIf: { fieldId: "type", fieldValues: ["3"] },
        choices: [
          { name: "1|1", value: "12" },
          { name: "1|2", value: "13" },
          { name: "1|3", value: "10" },
          { name: "1|4", value: "0" },
          { name: "1|5", value: "12" },
          { name: "2|1", value: "19" },
          { name: "2|2", value: "20" },
          { name: "2|3", value: "13" },
          { name: "2|4", value: "0" },
          { name: "2|5", value: "18" },
          { name: "3|1", value: "24" },
          { name: "3|2", value: "23" },
          { name: "3|3", value: "19" },
          { name: "3|4", value: "39" },
          { name: "3|5", value: "26" },
        ],
      },
      {
        id: "mileage",
        title: "Kilométrage réalisé sur une période maximale de 6 mois à compter du carénage x 2",
        showIf: { fieldId: "type", fieldValues: ["3"] },
        type: "number",
      },
    ],
    attachments: ["Relevé(s) de trafic", "Copie du titre de navigation"],
  },
  {
    name: "Gestion externalisée de la globalité du poste pneumatique (Véhicules de transport de marchandises)",
    id: "TRA-SE-108",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-SE-108.pdf",
    type: "Service",
    fields: [
      {
        id: "type",
        title: "Type de véhicules",
        type: "choice",
        choices: [
          {
            name: "Ensemble articulé",
            value: "4700",
          },
          {
            name: "Porteur",
            value: "1700",
          },
        ],
      },
      {
        id: "volume",
        type: "number",
        titles: [
          {
            fieldId: "type",
            fieldValue: "4700",
            title: "Nombre d'ensembles articulés en gestion externalisée",
          },
          {
            fieldId: "type",
            fieldValue: "1700",
            title: "Nombre de porteurs en gestion externalisée",
          },
        ],
        showIf: { fieldId: "type", fieldValues: ["4700", "1700"] },
      },
    ],
    attachments: [],
  },
  {
    name: "Gestion externalisée de la globalité du poste pneumatique (Véhicules de transport de personnes)",
    id: "TRA-SE-109",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-SE-109.pdf",
    type: "Service",
    fields: [
      {
        id: "amount",
        title: "Montant en kWh cumac par véhicule de catégorie M2 ou M3",
        type: "value",
        value: "580",
      },
      {
        id: "quantity",
        title: "Nombre de véhicules de catégorie M2 ou M3 en gestion externalisée",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Gestion optimisée de la globalité du poste pneumatique (Véhicules de transport de marchandises)",
    id: "TRA-SE-110",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-SE-110.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "type",
        title: "Type de véhicules",
        type: "choice",
        choices: [
          { name: "Ensemble articulé", value: "3900" },
          { name: "Porteur", value: "1400" },
        ],
      },
      {
        id: "quantity",
        titles: [
          { fieldId: "type", fieldValue: "3900", title: "Nombre d'ensembles articulés en gestion optimisée" },
          { fieldId: "type", fieldValue: "1400", title: "Nombre de porteurs en gestion optimisée" },
        ],
        type: "number",
        showIf: { fieldId: "type", fieldValues: ["3900", "1400"] },
      },
    ],
    attachments: [],
  },
  {
    name: "Gestion optimisée de la globalité du poste pneumatique (Véhicules de transport de personnes)",
    id: "TRA-SE-111",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-SE-111.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "amount",
        title: "Montant en kWh cumac par véhicule",
        type: "value",
        value: "430",
      },
      {
        id: "quantity",
        title: "Nombre de véhicules de catégorie M2 ou M3 en gestion optimisée",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Service d'autopartage en boucle",
    id: "TRA-SE-112",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-SE-112.pdf",
    type: "Service",
    fields: [
      {
        id: "amount",
        title: "Montant en kWh cumac pour un abonnement annuel à un service d'autopartage",
        type: "value",
        value: "6000",
      },
      {
        id: "quantity",
        title: "Nombre d'abonnements annuels au service d'autopartage",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Suivi des consommations de carburants grâce à des cartes privatives",
    id: "TRA-SE-113",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-SE-113.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "amount",
        title: "Montant en kWh cumac par véhicule",
        type: "value",
        value: "750",
      },
      {
        id: "quantity",
        title: "Nombre de cartes affectées à un seul véhicule de catégorie M1 ou N1",
        type: "number",
      },
    ],
    attachments: ["État récapitulatif"],
  },
  {
    name: "Covoiturage de longue distance",
    id: "TRA-SE-114",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-SE-114.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "amount",
        title: "Montant en kWh cumac par conducteur",
        type: "value",
        value: "18800",
      },
      {
        id: "quantity",
        title: "Nombre de conducteurs",
        type: "number",
      },
    ],
    attachments: ["Certificats d'horodatage", "Cachets de la contremarque de temps"],
  },
  {
    name: "Covoiturage de courte distance",
    id: "TRA-SE-115",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-SE-115.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "amount",
        title: "Montant en kWh cumac par conducteur",
        type: "value",
        value: "19700",
      },
      {
        id: "quantity",
        title: "Nombre de conducteurs (toutes plateformes confondues)",
        type: "number",
      },
    ],
    attachments: [
      "Certificats d'horodatage",
      "Cachets de la contremarque de temps",
      "Référence numérique attribuée par le registre de preuve de covoiturage",
    ],
  },
  {
    name: "Fret ferroviaire",
    id: "TRA-SE-116",
    formUrl: "https://gdceeforms.blob.core.windows.net/v65/TRA-SE-116.pdf",
    type: "Service",
    fields: [
      {
        id: "flux",
        title: "Flux de marchandise",
        type: "choice",
        choices: [
          { name: "Flux de marchandises existants (et précédemment transportées par route)", value: "0.190" },
          { name: "Nouveaux flux de marchandises", value: "1" },
        ],
      },
      {
        id: "category",
        title: "Catégories de marchandises du système de la NST 2007 (niveau 1)",
        type: "choice",
        choices: [
          { name: "01, 03, 04, 09", value: "0.172" },
          { name: "07, 08, 12", value: "0.142" },
          { name: "Autres catégories", value: "0.105" },
        ],
        showIf: { fieldId: "flux", fieldValues: ["1"] },
      },

      {
        id: "cr",
        title: "C/R (Durée du contrat / Durée du relevé de trafic)",
        type: "number",
      },
      {
        id: "t.km",
        title: "Nombre de t.km mentionné dans le relevé du trafic",
        type: "number",
      },
    ],
    attachments: [
      "Contrat objet",
      "Relevé de trafic routier",
      "Relevé de trafic ferroviaire",
      "Factures émises à l'attention du chargeur par l'opérateur",
      "Feuille de calcul",
      "Renouvellement du contrat",
      "Contrat initial (si renouvellement)",
    ],
    attachmentsShowIf: [{ attachmentId: 1, condition: { fieldId: "flux", fieldValues: ["0.195"] } }],
    startDate: "2025-01-01T02:00:00.000Z",
    history: [
      {
        name: "Fret ferroviaire",
        id: "TRA-SE-116",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/TRA-SE-116.pdf",
        type: "Service",
        fields: [
          {
            id: "flux",
            title: "Flux de marchandise",
            type: "choice",
            choices: [
              { name: "Flux de marchandises existants (et précédemment transportées par route)", value: "0.195" },
              { name: "Nouveaux flux de marchandises", value: "1" },
            ],
          },
          {
            id: "category",
            title: "Catégories de marchandises du système de la NST 2007 (niveau 1)",
            type: "choice",
            choices: [
              { name: "01, 03, 04, 09", value: "0.176" },
              { name: "07, 08, 12", value: "0.146" },
              { name: "Autres catégories", value: "0.108" },
            ],
            showIf: { fieldId: "flux", fieldValues: ["1"] },
          },
          {
            id: "cr",
            title: "C/R (Durée du contrat / Durée du relevé de trafic)",
            type: "number",
          },
          {
            id: "t.km",
            title: "Nombre de t.km mentionné dans le relevé du trafic",
            type: "number",
          },
        ],
        attachments: [
          "Contrat objet",
          "Relevé de trafic routier",
          "Relevé de trafic ferroviaire",
          "Factures émises à l'attention du chargeur par l'opérateur",
          "Feuille de calcul",
          "Renouvellement du contrat",
          "Contrat initial (si renouvellement)",
        ],
        attachmentsShowIf: [{ attachmentId: 1, condition: { fieldId: "flux", fieldValues: ["0.195"] } }],
      },
    ],
  },
];

export default traForms;

export const activeForms: string[] = traForms.filter((f) => f.fields.length > 0).map((f) => f.id);
